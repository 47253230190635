.new-user-popover {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;

  input, button {
    height: 32px;
    font-size: 14px;

    span {
      font-size: 14px;
    }
  }
}

.client-field {
  .ant-select-selection-search {
    input {
      height: 100%;
    }
  }
  .anticon-user-add {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}
