header.header {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #1D1E20;

  .logo {
    width: 60px;
    height: 60px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  .menu {
    cursor: pointer;
    margin: 0 0 0 auto
  }
}

.ant-dropdown {

  li {
    svg {
      margin: 0 5px 0 0;
    }

    span {
      font-size: 16px;
    }
  }
}