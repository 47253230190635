.order-modal-window {
  .ant-modal {
    width: 80vw !important;
    max-width: 1100px;
    height: 80vh;

    .modal-footer {
      .total-price {
        margin: 0 auto 0 0;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;
      }
    }


    .work-section {
      flex: 1;
      padding: 20px 0;
      overflow: auto;

      & > .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        grid-gap: 20px;
        margin: 0 0 20px;

        & > div {
          flex: 1;
        }

        .client-field {
          min-width: 30%;
          display: flex;
          align-items: center;
          flex-direction: row;

          div {
            flex: 1;
            margin: 0 10px 0 0;
          }

          .btn {
            font-size: 32px;
          }
        }
      }

      section {
        display: flex;
        flex-direction: column;
        margin: 40px 0 0 0;

        .section-header {
          color: #fff;
          border-bottom: 1px solid #121212;
          padding: 0 0 10px;
          margin: 0 0 20px;
        }

        .works-list {
          display: flex;
          flex-direction: column;

          .row {
            display: flex;
            border-bottom: 1px solid #121212;

            .th {
              flex: 1;
              border-right: 1px solid #121212;

              &:last-of-type {
                border: 0;
              }

              input, .ant-select-selector {
                background: none;
                outline: none !important;
                border: 0 !important;
                box-shadow: none;
              }
            }

            .index {
              min-height: 100%;
              max-width: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #fff;
            }

            .price {
              max-width: 200px;
            }

            .mechanic {
              max-width: 100px;
            }
          }
        }

        .total-row {
          height: 50px;

          .name {
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
            padding: 0 10px;

            svg {
              margin: 0 10px 0 0;
            }
          }

          .price {
            min-width: 300px;
            display: flex;
            align-items: center;
            padding: 0 0 0 12px;
            font-weight: bold;
            font-size: 18px;
          }
        }
      }
    }
  }

}