html {
    background-color: #35363B;
    color: #fff;
}

* {
    box-sizing: border-box;
    color: #fff;
}

body {
    height: 100vh;
    margin: 0;
    font-family: 'Arimo-Regular';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    background: rgba(53, 54, 59, 0.95);
    font-size: 16px;
}

#root {
    flex: 1;
    display: flex;
}

button {
    border: 0;
    background: none;
    font-size: 16px;
}

button:not(.icon) {
    height: 40px;
}

button:not(.icon) span {
    font-size: 16px;
}

button.icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
    transition: .2s ease-in-out;
    color: #fff;
    font-size: 30px;
}

button.icon:hover {
    color: #b6b6b6;
}

.form-control input,
.form-control .ant-select-selection-item,
.form-control .ant-select {
    width: 100%;
    height: 40px;
    font-size: 16px;
}

.ant-select-selector {
    height: 100% !important;
    display: flex;
    align-items: center;
}

.ant-select-selection-item {
    display: flex !important;
    align-items: center !important;
}

.ant-modal-content {
    overflow: hidden;
    height: 100%;
}

.ant-modal-body {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #121212;
    padding: 0 0 10px;
}

.modal-header h1 {
    margin: 0 auto 0 0;
    color: #fff;
}

.modal-footer {
    border-top: 2px solid #121212;
    padding: 15px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: auto 0 0 0;
    grid-gap: 20px;
}
