.home-page {
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.home-page .table {
  width: 100%;
  max-width: 100%;
  opacity: 0.8;
  overflow: hidden;
  padding: 20px;
  flex: 1;
}
.home-page .table .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-page .table .table-header h1 {
  margin: 0;
}
.home-page .table .actions .btn {
  margin: 0 0 0 10px;
}
.home-page .table .ant-table-tbody tr {
  cursor: pointer;
}
