.home-page {
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .table {
    width: 100%;
    max-width: 100%;
    opacity: 0.8;
    overflow: hidden;
    padding: 20px;
    flex: 1;

    .table-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        margin: 0;
      }
    }

    .actions {
      .btn {
        margin: 0 0 0 10px;
      }
    }

    .ant-table-tbody {
      tr {
        cursor: pointer;
      }
    }
  }
}
